/* @flow */
/* eslint-env browser */
import { Component } from 'react';
import debounce from 'lodash/debounce';
import fastdom from 'fastdom';
import styled, { css, ThemeProvider } from 'styled-components';
import { media, theme as UITheme } from '@eyeem-ui/theme';

import AppBanner from '../appBanner';
import LoggedIn from './navbarLoggedIn.jsx';
import NavbarInfo from './navbarInfo';

import { getUrlUnlocalized } from '../../helpers/localization';
import baseTheme from '../../helpers/theme';

import withRouter from '../../helpers/hoc/withRouter.jsx';
import { routeMatchesOneOf, isSearchPath } from '../../helpers/tools';
import {
  WEBFLOW_URLS,
  TOGGLE_SIDENAV_PUBSUB_EVENT,
} from '../../constants/misc';

const getLeftNavPosition = (props: Props) => {
  if (props.hasSideNav && !props.isMobile) {
    if (props.isSideNavOpen) {
      return props.theme.openSideNavWidth;
    }
    return props.theme.sideNavWidth;
  }
  return 0;
};

const StyledNavbar = css`
  position: ${({ currentPath }) =>
    isSearchPath(currentPath) ? 'relative' : 'sticky'};
  display: flex;
  justify-content: space-between;
  top: 0;

  z-index: ${baseTheme.zIndices.navBar};
  margin-bottom: 0;
  height: ${(props) => props.theme.navHeight};
  background-color: ${(props) =>
    props.isNavbarTransparent
      ? 'transparent'
      : props.theme.colors.blacks.black2};
  display: flex;
  height: ${(props) => props.theme.navHeight};
  justify-content: space-between;
  left: 0;
  margin-bottom: 0;
  position: ${({ currentPath }) =>
    isSearchPath(currentPath) ? 'relative' : 'sticky'};
  top: ${({ isAppBannerShown }) => (isAppBannerShown ? '56px' : '0')};
  transition: background-color 0.2s ease-in, left 0.2s ease-in;
  z-index: ${(props) => props.theme.zIndices.navBar};

  @media ${media.mediumUp} {
    left: ${(props) => getLeftNavPosition(props).toString()};
    position: ${({ currentPath }) =>
      isSearchPath(currentPath) ? 'relative' : 'fixed'};
    right: 0;
  }
`;

const StyledLoggedIn = styled(LoggedIn)`
  ${StyledNavbar};
  padding: 0 8px;
  position: sticky;
  flex-direction: column;
  background-color: ${(props) =>
    props.isNavbarTransparent ? 'transparent' : props.theme.colors.grey20};

  @media ${media.mediumUp} {
    border-left: ${({ theme, isNavbarTransparent }) =>
      isNavbarTransparent
        ? `1px solid transparent`
        : `1px solid ${theme.colors.grey0}`};
    position: ${({ currentPath, isAuthenticated }) =>
      isSearchPath(currentPath) && !isAuthenticated ? 'sticky' : 'fixed'};
    padding: 0 12px;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0;
  }
`;

const StyledNavbarInfo = styled(NavbarInfo)`
  ${StyledNavbar};
`;

type Props = {
  authUser: AuthUser,
  hasNoSearchTerm: boolean,
  hasSideNav: boolean,
  isAndroid: boolean,
  isAuthenticated: boolean,
  isBCG: boolean,
  isBuyer: boolean,
  isLoginViaParam: boolean,
  isEnterpriseCustomer: boolean,
  isInExternalPage: boolean,
  isIOS: boolean,
  isLightboxStripOpen: boolean,
  isMobile: boolean,
  isPhotographer: boolean,
  isSeller: boolean,
  isSideNavOpen: boolean,
  isAuthenticatedBuyer: boolean,
  serverRoute: string,
  setSideNavOpen: Function,
} & WithRouterProps;

type State = {
  isSideNavOpen: boolean,
  scrolled: boolean,
};

class Navbar extends Component<Props, State> {
  state = {
    scrolled: false,
    isSideNavOpen: this.props.isSideNavOpen,
  };

  componentDidMount() {
    // using pub-sub for WebFlow pages, since they have the side nav and the top nav
    // as two different apps
    if (routeMatchesOneOf(this.currentPath(), WEBFLOW_URLS) && window.mitt) {
      window.mitt.on(TOGGLE_SIDENAV_PUBSUB_EVENT, this.handleSidenavToggle);
    }

    window.addEventListener('scroll', this.handleScroll);
    // the page could be scrolled already, let's check
    this.handleScroll();
  }

  // this.state.isSideNavOpen can come from the session fields
  // but also from mitt events (if we're on a Webflow page)
  componentDidUpdate(prevProps) {
    if (prevProps.isSideNavOpen !== this.props.isSideNavOpen) {
      // https://reactjs.org/docs/react-component.html#componentdidupdate
      this.setState({ isSideNavOpen: this.props.isSideNavOpen }); // eslint-disable-line
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  currentPath = () => this.props.getPath() || this.props.serverRoute;

  isNavbarTransparent = () => {
    if (this.props.isLightboxStripOpen) {
      return false;
    }

    const routes = [
      '/',
      '/market/licensing',
      '/missions/photographers',
      '/pictures/:collectionId?',
      '/bilder/:collectionId?',
      '/u/:nickname',
      '/u/:nickname/illustrations',
      '/u/:nickname/market',
      '/u/:nickname/partner',
      '/u/:nickname/photos',
      '/a/:albumId/:paginatableName?',
      ...WEBFLOW_URLS,
    ];

    return (
      routeMatchesOneOf(this.currentPath(), routes) && !this.state.scrolled
    );
  };

  handleSidenavToggle = (payload: { isOpen: boolean }) => {
    this.setState({ isSideNavOpen: payload.isOpen });
  };

  handleScroll = debounce(() => {
    fastdom.measure(() => {
      const scrolled = window.pageYOffset > 20;

      if (this.state.scrolled !== scrolled) {
        this.setState({ scrolled });
      }
    });
  }, 10);

  isOnRouteWithSearchInput = () =>
    routeMatchesOneOf(this.currentPath(), [
      '/missions',
      '/missions/photographers',
      '/market/licensing',
      '/pictures/:collectionId?',
      '/bilder/:collectionId?',
      '/u/:nickname/:paginatableName?',
      '/a/:albumId/:paginatableName?',
    ]);

  render() {
    const path = getUrlUnlocalized(this.currentPath());
    const sideNavProps = {
      hasSideNav: this.props.hasSideNav,
      isSideNavOpen: this.state.isSideNavOpen,
      setSideNavOpen: this.props.setSideNavOpen,
      isNavbarTransparent: this.isNavbarTransparent(),
      currentPath: path,
      isScrolled: this.state.scrolled,
      isMobile: this.props.isMobile,
    };

    // Embedded views are used by native app webviews
    const isEmbeddedView = this.props.isLoginViaParam;

    const isMobileWeb =
      (this.props.isAndroid || this.props.isIOS) && this.props.isMobile;
    const isLoggedOutOrPhotographer =
      !this.props.isAuthenticated || this.props.isPhotographer;

    const isAppBannerShown =
      !isEmbeddedView &&
      isMobileWeb &&
      isLoggedOutOrPhotographer &&
      routeMatchesOneOf(this.currentPath(), [
        '/a/:albumId/:page?',
        '/i/:assetId',
        '/m/:missionId/:page?',
        '/p/:assetId',
        '/u/:nickname/:page?',
      ]);

    if (
      this.props.hasSideNav &&
      (this.props.isBuyer || this.props.isPhotographer)
    ) {
      return (
        <>
          {isAppBannerShown && <AppBanner path={this.currentPath()} />}
          <ThemeProvider
            theme={{
              ...baseTheme,
              colors: {
                ...baseTheme.colors,
                ...UITheme.colors,
              },
            }}>
            <StyledLoggedIn
              authUser={this.props.authUser}
              isAppBannerShown={isAppBannerShown}
              isAuthenticated={this.props.isAuthenticated}
              isBuyer={this.props.isBuyer}
              isSeller={this.props.isSeller}
              isEnterpriseCustomer={this.props.isEnterpriseCustomer}
              isInExternalPage={this.props.isInExternalPage}
              isOnRouteWithSearchInput={this.isOnRouteWithSearchInput()}
              isAuthenticatedBuyer={this.props.isAuthenticatedBuyer}
              {...sideNavProps}
            />
          </ThemeProvider>
        </>
      );
    }
    return (
      <>
        {isAppBannerShown && <AppBanner path={this.currentPath()} />}
        <StyledNavbarInfo
          isAppBannerShown={isAppBannerShown}
          isAuthenticated={this.props.isAuthenticated}
          {...sideNavProps}
        />
      </>
    );
  }
}

export default withRouter(Navbar);
