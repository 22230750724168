import { ThemeProvider } from '@eyeem-ui/theme';
import { Flex, Text } from '@eyeem-ui/atoms';

import t from 'counterpart';

import StyledNavIconWrapper from './navBarItemWrapper';
import Link from '../general/link';

export const ContactSalesButtonNavbarLoggedIn = () => {
  return (
    <ThemeProvider>
      <Flex alignItems="center">
        <Link href="/market/pricing#form">
          <StyledNavIconWrapper p={2}>
            <Text variant="title6" bold>
              {t('navbar.info.contactSales')}
            </Text>
          </StyledNavIconWrapper>
        </Link>
      </Flex>
    </ThemeProvider>
  );
};
