/* @flow */
/* Lightbox Icon in navbar to toggle lightbox strip open state */
/* eslint-env browser */
import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import t from 'counterpart';
import { Flex } from '@eyeem-ui/atoms';
import { Lightbox as LightboxIcon } from '@eyeem-ui/icons';
import { ThemeProvider } from '@eyeem-ui/theme';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Flyout from '../../general/flyout.jsx';
import Link from '../../general/link/';
import Photo from '../../general/photo/';
import T from '../../general/translate.jsx';

import { FLYOUT_TIMEOUT } from '../../../constants/misc';
import { GET_PHOTO_FOR_LIGHTBOX_STRIP } from '../../../graphql/queries/photo';
import { GET_LIGHTBOX } from '../../../graphql/queries/lightbox';

import StyledNavIconWrapper from '../navBarItemWrapper';

type Props = {
  eventPosition: string,
  toggleLightboxStrip: Function,
  isInExternalPage: boolean,
  isLightboxStripOpen: boolean,
  flyoutAsset: EyeEmAsset,
  photo: EyeEmPhoto,
  flyout: FlyoutState,
  clearFlyout: Function,
  isTablet: boolean,
  isEnterpriseCustomer: boolean,
  photoId: EyeEmAssetId,
  contextLightboxId: String,
};

const Lightbox = (props: Props) => {
  useEffect(() => {
    return () => {
      // cleanup flyout when leaving
      clearTimeout(window.flyoutTimer);
      window.flyoutTimer = setTimeout(
        () => props.clearFlyout(),
        FLYOUT_TIMEOUT
      );
    };
  }, []);

  const { data } = useQuery(GET_PHOTO_FOR_LIGHTBOX_STRIP, {
    variables: { photoId: props.photoId, isPhotographer: false },
    skip: !props.photoId,
  });
  const { data: contextLightboxData } = useQuery(GET_LIGHTBOX, {
    variables: { lightboxId: props.contextLightboxId },
    skip: !props.contextLightboxId,
    ssr: false,
  });

  const toggle = () =>
    props.toggleLightboxStrip({
      isOpen: props.isLightboxStripOpen,
    });

  const asset = data?.photo || props.flyoutAsset;

  const shouldShowFlyout = props.flyout?.flyoutType === 'lightbox';
  return (
    <Flex alignItems="center">
      <T
        noTranslate
        eventName={t(
          props.isLightboxStripOpen
            ? 'navbar.lightbox.eventName.close'
            : 'navbar.lightbox.eventName.open'
        )}
        eventType={t('tracking.eventType.inbound')}
        eventPosition={props.eventPosition}
        eventAction={t('tracking.eventAction.icon')}
        eventLabel={t('navbar.lightbox.eventLabel')}
        title={t('navbar.lightbox.label')}
        onClick={props.isInExternalPage ? undefined : toggle}
        component={props.isInExternalPage ? 'a' : undefined}
        href={props.isInExternalPage ? '/lightboxes' : undefined}>
        <ThemeProvider>
          <StyledNavIconWrapper p={2} justifyContent="center">
            <LightboxIcon size="24" />
          </StyledNavIconWrapper>
        </ThemeProvider>
      </T>
      <TransitionGroup>
        {shouldShowFlyout && (
          <CSSTransition
            classNames="transition_dropdown"
            timeout={{ enter: 700, exit: 400 }}>
            <Flyout flyoutStyle="lightbox">
              <div className="g_media position-relative">
                {asset ? (
                  <Photo
                    url={asset.previewUrl}
                    size1="sq"
                    size2={48}
                    className="g_media-left"
                  />
                ) : null}
                <div className="g_media-body">
                  <T
                    component="div"
                    className="typo_pStatic typo-noMargin typo-color-grey3">
                    {[
                      'lightboxes.flyout',
                      asset.type,
                      props.flyout.added ? 'added' : 'removed',
                    ]}
                  </T>
                  <T
                    noTranslate
                    component={Link}
                    className="g_link typo_pStatic typo_pStatic-bold g_flyout-textOverflow"
                    eventType={t('tracking.eventType.inbound')}
                    eventAction={t('tracking.eventAction.link')}
                    eventName={t('navbar.lightbox.flyout.eventName')}
                    eventLabel={contextLightboxData?.lightbox?.name}
                    eventPosition={t('navbar.lightbox.flyout.eventPosition')}
                    href={`/lightboxes/${contextLightboxData?.lightbox?.id}`}>
                    {contextLightboxData?.lightbox?.name}
                  </T>
                </div>
              </div>
            </Flyout>
          </CSSTransition>
        )}
      </TransitionGroup>
    </Flex>
  );
};

export default Lightbox;
