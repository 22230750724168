/* @flow */
/* Enterprise Navbar */

// Libraries
import { useState } from 'react';
import t from 'counterpart';
import styled from '@emotion/styled';
import { Box, Flex, Button } from '@eyeem-ui/atoms';
import { Search } from '@eyeem-ui/icons';
import { ThemeProvider } from '@eyeem-ui/theme';

import {
  StyledSearchBarWrapperWide,
  StyledLeftNavListNoPadding,
  StyledRightNavList,
} from './styles';
import baseTheme from '../../helpers/theme';

import SearchBar from '../general/searchBar/';

// Direct Child Components
import PreviousDownloadsButton from './previousDownloadsButton';
import Lightbox from './lightbox/';
import LicenceLink from './licenceLink/';
import Cart from './cart/';
import Pricing from './pricing';
import SideNavToggle from './sideNavToggle';
import ZenDeskButton from './zenDeskButton';
import { ContactSalesButtonNavbarLoggedIn } from './contactSalesButtonNavbarLoggedIn';

const SearchNavItem = styled('li')`
  display: flex;
  align-items: center;
  position: relative;
  min-width: ${(props) => (props.isMobileSearchOpen ? '100%' : 'inherit')};
`;

const StyledSearchArea = styled(Flex)`
  position: absolute;
  top: 0;
  height: 100%;
  z-index: ${(props) => (props.isMobileSearchOpen ? 1 : -1)};
  opacity: ${(props) => (props.isMobileSearchOpen ? 1 : 0)};
  transition: opacity 0.05s ease;
  background-color: ${(props) =>
    props.isScrolled ? props.theme.colors.grey20 : 'transparent'};
`;

const SideNavToggleWrapper = styled('ul')`
  visibility: ${(props) => (props.isMobileSearchOpen ? 'hidden' : 'inherit')};
  height: ${() => baseTheme.navHeight};
  display: flex;
  align-items: center;
`;

const SearchIconWrapper = styled('span')`
  cursor: pointer;
  display: ${(props) => (props.isMobileSearchOpen ? 'none' : 'inherit')};
`;

function NavbarLoggedIn({
  className,
  currentPath,
  isInExternalPage,
  isNavbarTransparent,
  isScrolled,
  isOnRouteWithSearchInput,
  isMobile,
  isEnterpriseCustomer,
  isBuyer,
  isSeller,
  isSideNavOpen,
  isAuthenticatedBuyer,
}: {
  className: string,
  currentPath: string,
  isInExternalPage: boolean,
  isNavbarTransparent: boolean,
  isScrolled: boolean,
  isOnRouteWithSearchInput: boolean,
  isMobile: boolean,
  isEnterpriseCustomer: boolean,
  isBuyer: boolean,
  isSeller: boolean,
  isSideNavOpen: boolean,
  isAuthenticatedBuyer: boolean,
}) {
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
  const eventPosition = t('navbar.eventPosition.enterprise');
  const isSearchWrapperHidden = currentPath === '/' && !isScrolled;

  const showSearchInput =
    (isOnRouteWithSearchInput ||
      (!isSearchWrapperHidden && !isNavbarTransparent)) &&
    !isMobile;

  return (
    <nav className={className}>
      <Flex width={1} justifyContent="space-between" alignItems="center">
        <StyledLeftNavListNoPadding>
          {isMobile && (
            <SideNavToggleWrapper isMobileSearchOpen={isMobileSearchOpen}>
              <ThemeProvider>
                <SideNavToggle isOpen={isSideNavOpen} />
              </ThemeProvider>
            </SideNavToggleWrapper>
          )}
          <StyledSearchBarWrapperWide showSearchInput={showSearchInput}>
            <SearchBar
              currentPath={currentPath}
              eventPosition={eventPosition}
            />
          </StyledSearchBarWrapperWide>
        </StyledLeftNavListNoPadding>
        <StyledRightNavList isMobileSearchOpen={isMobileSearchOpen}>
          {isAuthenticatedBuyer && !isMobileSearchOpen && (
            <>
              <Pricing />
              {!isEnterpriseCustomer && <ContactSalesButtonNavbarLoggedIn />}
            </>
          )}
          {isMobile && (
            <SearchNavItem isMobileSearchOpen={isMobileSearchOpen}>
              <SearchIconWrapper
                isMobileSearchOpen={isMobileSearchOpen}
                role="button"
                tabIndex={0}
                onClick={() => setIsMobileSearchOpen(!isMobileSearchOpen)}>
                <Box mr={2}>
                  <ThemeProvider>
                    <Search size="24" />
                  </ThemeProvider>
                </Box>
              </SearchIconWrapper>
              {isMobileSearchOpen && (
                <ThemeProvider>
                  <StyledSearchArea
                    justifyContent="space-between"
                    alignItems="center"
                    isScrolled={isScrolled}
                    isMobileSearchOpen={isMobileSearchOpen}
                    pr={3}
                    width={1}>
                    <Box flex="1 1 auto">
                      <SearchBar
                        currentPath={currentPath}
                        eventPosition={eventPosition}
                      />
                    </Box>

                    <Box ml={2}>
                      <Button
                        variant="transparent"
                        onClick={() => setIsMobileSearchOpen(false)}>
                        {t('navbar.search.cancel')}
                      </Button>
                    </Box>
                  </StyledSearchArea>
                </ThemeProvider>
              )}
            </SearchNavItem>
          )}

          {isSeller && !isMobile && <ZenDeskButton isMobile={isMobile} />}

          {isBuyer && !isMobileSearchOpen && (
            <>
              {!isMobile && (
                <Lightbox
                  isInExternalPage={isInExternalPage}
                  eventPosition={eventPosition}
                />
              )}
              <PreviousDownloadsButton
                isInExternalPage={isInExternalPage}
                eventPosition={eventPosition}
              />
              <LicenceLink
                isInExternalPage={isInExternalPage}
                eventPosition={eventPosition}
              />

              {!isEnterpriseCustomer && (
                <ThemeProvider>
                  <Flex alignItems="center">
                    <Cart />
                  </Flex>
                </ThemeProvider>
              )}
            </>
          )}
        </StyledRightNavList>
      </Flex>
    </nav>
  );
}

export default NavbarLoggedIn;
