import { connect } from 'react-redux';
import { getDevice, getIsLoginViaParam } from '../../reducers/';

import AppBanner from './appBanner';

const mapStateToProps = (state, ownProps) => {
  const { operatingSystem, isIOS } = getDevice(state);

  return {
    ...ownProps,
    isIOS,
    isLoginViaParam: getIsLoginViaParam(state),
    operatingSystem,
  };
};

export default connect(mapStateToProps)(AppBanner);
