/* @flow */
/* Navbar Cart Icon and Flyout */
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import find from 'lodash/find';
import get from 'lodash/get';
import t from 'counterpart';
import styled from '@emotion/styled';
import { Box, Button } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';
import { Cart } from '@eyeem-ui/icons';

import Flyout from '../../general/flyout.jsx';
import Link from '../../general/link/';
import Photo from '../../general/photo/';
import Price from '../../general/price.jsx';
import T from '../../general/translate.jsx';
import StyledNavIconWrapper from '../navBarItemWrapper';
import {
  track,
  getEcommerceEvent,
  isPremiumPhoto,
  getSelectedLicenseForPhoto,
} from '../../../helpers/tools';
import {
  TEST_CART_COUNT,
  TEST_NAVBAR_CART,
} from '../../../constants/pageObjectSelectors';

import { StyledNavItemIconCount } from '../styles';

const StyledRelativeBox = styled(Box)`
  position: relative;
`;

function NavbarCart(props: {
  photo: EyeEmPhoto,
  cartPhotos: $ReadOnlyArray<EyeEmCartItemPhoto>,
  flyout: Flyout,
  clearFlyout: () => void,
  isInExternalPage: boolean,
  isTablet: boolean,
  currency: string,
  userLicences: LicensingOption,
  cartDeals: $ReadOnlyArray<EyeEmCartItemDeal>,
  isAuthenticated: boolean,
}) {
  const selectedLicenseId = get(props, 'flyout.item.distributionLicenseId');

  const ecommerceEvent = () => {
    const cartPhotos = props.cartPhotos.map((item) => {
      if (item.selectedLicense) {
        return item;
      }
      return {
        ...item,
        selectedLicense: getSelectedLicenseForPhoto(
          item,
          props.userLicences,
          item.distributionLicenseId
        ),
      };
    });
    return getEcommerceEvent({
      cartPhotos,
      cartDeals: props.cartDeals,
      actionFields: { step: 1 },
    });
  };

  const license = find(
    get(props, 'userLicences'),
    (licenseObject) => licenseObject.id === selectedLicenseId
  );

  const shouldShowCartFlyout =
    get(props.flyout, 'flyoutType') === 'cart' && license && props.photo;

  const shouldShowCartLightboxFlyout =
    get(props.flyout, 'flyoutType') === 'cart-lightbox';

  const cartItemCount = props.cartPhotos && props.cartPhotos.length;

  const getPrice = () => {
    if (
      isPremiumPhoto(props.photo) &&
      license.pricePoints.premium.type === 'price'
    ) {
      return license.pricePoints.premium.price;
    }

    if (license.pricePoints.regular.type === 'price') {
      return license.pricePoints.regular.price;
    }

    return '';
  };
  return (
    <StyledRelativeBox>
      <T
        data-test-id={TEST_NAVBAR_CART}
        title={t('cart.cartTitle')}
        noTranslate
        eventName={t('navbar.cart.eventName')}
        eventAction={t('tracking.eventAction.icon')}
        eventLabel={t('navbar.cart.eventLabel')}
        eventPosition={'topbar_nav'}
        eventType={t('tracking.eventType.inbound')}
        component={props.isInExternalPage ? 'a' : Link}
        href="/market/cart">
        <StyledNavIconWrapper p={2} justifyContent="center">
          <Cart size="24" />
        </StyledNavIconWrapper>
        <ThemeProvider>
          <StyledNavItemIconCount
            itemCount={cartItemCount}
            data-test-id={TEST_CART_COUNT}>
            {cartItemCount}
          </StyledNavItemIconCount>
        </ThemeProvider>
      </T>
      <TransitionGroup>
        {shouldShowCartFlyout && (
          <CSSTransition
            classNames="transition_dropdown"
            timeout={{ enter: 700, exit: 400 }}>
            <Flyout flyoutStyle="cart">
              <div className="g_media position-relative">
                <div className="g_media-right">
                  <Price
                    amount={getPrice()}
                    currency={props.currency}
                    truncate
                  />
                </div>
                <Photo
                  url={props.photo.previewUrl}
                  size1="sq"
                  size2={48}
                  link="/market/cart"
                  className="g_media-left"
                />
                <div className="g_media-body">
                  <div className="typo_pStatic typo-noMargin typo-color-grey3">
                    #{props.photo.id}
                  </div>
                  <T
                    className="typo_pStatic g_flyout-textOverflow typo-color-grey3"
                    user={props.photo.user.fullname}
                    component="div">
                    checkout.addToCart.flyout.by
                  </T>
                </div>
              </div>

              <Box pt={2}>
                <Link
                  href={
                    props.isAuthenticated
                      ? '/market/checkout'
                      : '/market/authenticate'
                  }>
                  <Button
                    fullWidth
                    onClick={() => {
                      track({
                        eventType: t('tracking.eventType.inbound'),
                        eventName: t(
                          'checkout.addToCart.flyout.checkout.eventName'
                        ),
                        eventAction: t('tracking.eventAction.button'),
                        eventLabel: t('checkout.addToCart.flyout.checkout.go'),
                        eventPosition: t(
                          'checkout.addToCart.flyout.checkout.eventPosition'
                        ),
                        eventEcommerce: ecommerceEvent(),
                      });
                      props.clearFlyout();
                    }}>
                    {t('checkout.addToCart.flyout.checkout.go')}
                  </Button>
                </Link>
              </Box>
              <Box pt={2}>
                <Link href="/market/cart">
                  <Button
                    variant="secondary"
                    fullWidth
                    onClick={() => {
                      track({
                        eventType: t('tracking.eventType.inbound'),
                        eventName: t(
                          'checkout.addToCart.flyout.cart.eventName'
                        ),
                        eventAction: t('tracking.eventAction.button'),
                        eventLabel: t('checkout.addToCart.flyout.cart.go'),
                        eventPosition: t(
                          'checkout.addToCart.flyout.cart.eventPosition'
                        ),
                      });
                      props.clearFlyout();
                    }}>
                    {t('checkout.addToCart.flyout.cart.go')}
                  </Button>
                </Link>
              </Box>
            </Flyout>
          </CSSTransition>
        )}
        {shouldShowCartLightboxFlyout && (
          <CSSTransition
            classNames="transition_dropdown"
            timeout={{ enter: 700, exit: 400 }}>
            <Flyout flyoutStyle="cart-lightbox">
              <Box pt={2}>
                <Link href="/market/cart">
                  <Button
                    fullWidth
                    onClick={() => {
                      track({
                        eventType: t('tracking.eventType.inbound'),
                        eventName: t(
                          'checkout.addToCart.flyout.cart.eventName'
                        ),
                        eventAction: t('tracking.eventAction.button'),
                        eventLabel: t('checkout.addToCart.flyout.cart.go'),
                        eventPosition: t(
                          'checkout.addToCart.flyout.cart.eventPosition'
                        ),
                      });
                      props.clearFlyout();
                    }}>
                    {t('checkout.addToCart.flyout.cart.go')}
                  </Button>
                </Link>
              </Box>
            </Flyout>
          </CSSTransition>
        )}
      </TransitionGroup>
    </StyledRelativeBox>
  );
}

export default NavbarCart;
