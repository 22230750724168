import t from 'counterpart';
import { Flex, Box, Text } from '@eyeem-ui/atoms';

function EmptyStateBuyer() {
  return (
    <Flex alignItems="center" flexDirection="column">
      <Box pt={1}>
        <img alt="deal icon" src="/node-static/img/checkout/deal_white.svg" />
      </Box>
      <Box pt={2}>
        <Text bold>{t('navbar.licensesMenu.emptyStateBuyer.title')}</Text>
      </Box>
      <Box py={2}>
        <Text color="ghost70" variant="title6">
          {t('navbar.licensesMenu.emptyStateBuyer.subline')}
        </Text>
      </Box>
    </Flex>
  );
}

export default EmptyStateBuyer;
