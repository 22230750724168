/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@theme-ui/core'; // eslint-disable-line

import { ThemeProvider } from '@eyeem-ui/theme';
import { ChatBubbles } from '@eyeem-ui/icons';
import { Box, Button } from '@eyeem-ui/atoms';

const ZenDeskButton = () => (
  <ThemeProvider>
    <Box py={'12px'}>
      <a href="https://eyeem.zendesk.com/hc/en-us/categories/115000290289-Frequently-Asked-Questions">
        <Button
          style={{
            padding: '8px',
          }}
          variant="secondary">
          <ChatBubbles size="24" />
        </Button>
      </a>
    </Box>
  </ThemeProvider>
);

export default ZenDeskButton;
