/* @flow */
import t from 'counterpart';
import { Flex } from '@eyeem-ui/atoms';
import { DownloadFolder } from '@eyeem-ui/icons';
import { ThemeProvider } from '@eyeem-ui/theme';

import withRouter from '../../../helpers/hoc/withRouter.jsx';
import { track } from '../../../helpers/tools';
import { PREVIOUS_DOWNLOADS_PHOTOS_PATH } from '../../../constants/misc';

import Link from '../../general/link/';
import StyledNavIconWrapper from '../navBarItemWrapper';

function PreviousDownloadsButton(
  props: {
    isInExternalPage: boolean,
    eventPosition: string,
  } & WithRouterProps
) {
  const translateString = 'navbar.myCollection.label';
  const isActive = props.getPath() === PREVIOUS_DOWNLOADS_PHOTOS_PATH;
  return (
    <Flex alignItems="center" isActive={isActive} title={t(translateString)}>
      <Link
        onClick={() =>
          track({
            eventName: t('navbar.myCollection.eventName'),
            eventType: t('tracking.eventType.inbound'),
            eventPosition: props.eventPosition,
            eventAction: t('tracking.eventAction.link'),
            eventLabel: t('navbar.myCollection.eventLabel'),
          })
        }
        href={PREVIOUS_DOWNLOADS_PHOTOS_PATH}
        title={t(translateString)}>
        <ThemeProvider>
          <StyledNavIconWrapper p={2} justifyContent="center">
            <DownloadFolder size="24" />
          </StyledNavIconWrapper>
        </ThemeProvider>
      </Link>
    </Flex>
  );
}

export default withRouter(PreviousDownloadsButton);
