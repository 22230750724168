import { connect } from 'react-redux';
import { compose } from 'redux';
import Navbar from './navbar';
import withRouter from '../../helpers/hoc/withRouter';
import {
  getAuthUser,
  getDevice,
  getIsAuthenticated,
  getIsBCG,
  getIsBuyer,
  getIsEnterpriseCustomer,
  getIsLightboxStripOpen,
  getIsLoginViaParam,
  getIsMobile,
  getIsPhotographer,
  getIsSeller,
  getSessionField,
  getIsAuthenticatedBuyer,
} from '../../reducers/';

import { _hasNoSearchTerm } from '../../helpers/search';

import {
  SESSION_FIELD_HAS_SIDE_NAV,
  SESSION_FIELD_SIDE_NAV_OPEN,
} from '../../constants/misc';

const mapStateToProps = (state, ownProps) => {
  const { isAndroid, isIOS } = getDevice(state);

  return {
    authUser: getAuthUser(state),
    hasNoSearchTerm: _hasNoSearchTerm(ownProps.getPath()),
    hasSideNav: !!getSessionField(state, SESSION_FIELD_HAS_SIDE_NAV),
    isAndroid,
    isAuthenticated: getIsAuthenticated(state),
    isBCG: getIsBCG(state),
    isBuyer: getIsBuyer(state),
    isEnterpriseCustomer: getIsEnterpriseCustomer(state),
    isIOS,
    isLightboxStripOpen: getIsLightboxStripOpen(state),
    isLoginViaParam: getIsLoginViaParam(state),
    isMobile: getIsMobile(state),
    isPhotographer: getIsPhotographer(state),
    isSeller: getIsSeller(state),
    isSideNavOpen: !!getSessionField(state, SESSION_FIELD_SIDE_NAV_OPEN),
    isAuthenticatedBuyer: getIsAuthenticatedBuyer(state),
  };
};

export default compose(withRouter, connect(mapStateToProps))(Navbar);
