// @flow
import { gql } from '@apollo/client';

import { SimplePhotoFragment } from '../fragments/photo';
import { SimpleUserFragment } from '../fragments/user';

export const GET_PHOTO = gql`
  query getPhoto($photoId: ID!, $isPhotographer: Boolean!) {
    photo(photoId: $photoId, isPhotographer: $isPhotographer) {
      ...SimplePhoto
      webUrl
      updated
      updatedAt
      hidden
      blacklisted
      submittedToMarket
      user {
        ...SimpleUser
        follower
        following
        description
      }
      totalLikes @include(if: $isPhotographer)
      totalComments @include(if: $isPhotographer)
      description
      simpleDescription
      liked @include(if: $isPhotographer)
      lightboxIds @skip(if: $isPhotographer)
      marketStatus
      partnerStatus {
        premium
      }
      albums {
        items {
          id
          name
          type
        }
      }
      likers @include(if: $isPhotographer) {
        offset
        limit
        total
        items {
          ...SimpleUser
        }
      }
      getty {
        headline
        tags
      }
      eyevision {
        headline
        tags
      }
      cart
      isForSale
    }
  }
  ${SimplePhotoFragment}
  ${SimpleUserFragment}
`;

export const GET_PHOTO_FOR_DIRECT_DOWNLOAD = gql`
  query getPhotoForDirectDownload($photoId: ID!, $isPhotographer: Boolean!) {
    photo(photoId: $photoId, isPhotographer: $isPhotographer) {
      ...SimplePhoto
      cart
      user {
        ...SimpleUser
      }
    }
  }
  ${SimplePhotoFragment}
  ${SimpleUserFragment}
`;

export const GET_PHOTO_FOR_LIGHTBOX_STRIP = gql`
  query getPhotoForLightboxStrip($photoId: ID!, $isPhotographer: Boolean!) {
    photo(photoId: $photoId, isPhotographer: $isPhotographer) {
      id
      type
      previewUrl: photoUrl
      lightboxIds
    }
  }
`;

export const GET_PHOTO_WITH_RELEASE_LIST = gql`
  query getPhotoWithReleaseList($photoId: ID!, $isPhotographer: Boolean!) {
    photo(photoId: $photoId, isPhotographer: $isPhotographer) {
      id
      releases {
        needs_model
        needs_property
        model {
          id
          releaseType
          userId
          status
          link
          tosAcceptedAt
          createdAt
          updatedAt
          photoIds
          releaseUrl
          firstName
          lastName
          multiReleaseId
        }
        property {
          id
          releaseType
          userId
          status
          link
          tosAcceptedAt
          createdAt
          updatedAt
          photoIds
          releaseUrl
          ownerFirstName
          ownerLastName
          multiReleaseId
        }
      }
    }
  }
`;

export const GET_PHOTO_FOR_MISSIONS = gql`
  query getPhotoForMissions($photoId: ID!, $isPhotographer: Boolean!) {
    photo(photoId: $photoId, isPhotographer: $isPhotographer) {
      ...SimplePhoto
      webUrl
      updated
      updatedAt
      user {
        ...SimpleUser
        follower
        following
        description
      }
      totalLikes @include(if: $isPhotographer)
      totalComments @include(if: $isPhotographer)
      liked @include(if: $isPhotographer)
    }
  }
  ${SimplePhotoFragment}
  ${SimpleUserFragment}
`;
