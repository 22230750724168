// @flow
import { gql } from '@apollo/client';

export const GET_LICENSES = gql`
  query getLicenses {
    licenses {
      id
      name
      pricePoints {
        premium {
          ... on Single {
            type
            price
            currency
          }
          ... on Pack {
            type
            available
            remaining
            used
          }
        }
        regular {
          ... on Single {
            type
            price
            currency
          }
          ... on Pack {
            type
            available
            remaining
            used
          }
        }
      }
    }
  }
`;

export const GET_LICENSING_INFO_FOR_ASSET = gql`
  query getLicensingInfo($assetId: ID!, $assetType: AssetType!) {
    licensingInfo(assetId: $assetId, assetType: $assetType) {
      id
      availableLicenses {
        id
        downloadType
        payment {
          type
          price
          currency
          available
          remaining
        }
      }
      purchasedLicense {
        id
      }
    }
  }
`;
