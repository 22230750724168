/* @flow */
import { connect } from 'react-redux';

import { getIsEnterpriseCustomer } from '../../../../reducers/';

import EnterpriseLicenseData from './EnterpriseLicenseData.jsx';

const mapStateToProps = (state) => ({
  isEnterpriseCustomer: getIsEnterpriseCustomer(state),
});

export default connect(mapStateToProps)(EnterpriseLicenseData);
