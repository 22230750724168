/* @flow */
import { ThemeProvider, media } from '@eyeem-ui/theme';
import { Earnings } from '@eyeem-ui/icons';
import { Flex, Text } from '@eyeem-ui/atoms';
import styled from '@emotion/styled';
import t from 'counterpart';
import Link from '../../general/link';
import { track } from '../../../helpers/tools';

import StyledNavIconWrapper from '../navBarItemWrapper';

const PricingSmall = styled(StyledNavIconWrapper)`
  display: inline-block;
  @media ${media.mediumUp} {
    display: none;
  }
`;

const PricingMediumUp = styled(StyledNavIconWrapper)`
  display: none;
  @media ${media.mediumUp} {
    display: inline-block;
  }
`;

const trackClick = () => {
  track({
    eventType: 'click_inbound',
    eventName: 'access_pricing',
    eventAction: 'click_icon',
    eventLabel: 'icon',
    eventPosition: 'topbar_nav',
  });
};

function Pricing() {
  return (
    <ThemeProvider>
      <Flex alignItems="center">
        <Link href="/market/pricing" onClick={trackClick}>
          <PricingMediumUp p={2}>
            <Text variant="title6" bold>
              {t('navbar.pricing')}
            </Text>
          </PricingMediumUp>
          <PricingSmall p={2}>
            <Earnings size="24" />
          </PricingSmall>
        </Link>
      </Flex>
    </ThemeProvider>
  );
}

export default Pricing;
