/* eslint-disable react/prefer-stateless-function */
// @flow
import t from 'counterpart';
import find from 'lodash/find';
import shortid from 'shortid';
import { Flex, Box, Button, Text } from '@eyeem-ui/atoms';
import styled from '@emotion/styled';
import { useQuery } from '@apollo/client';

import {
  AVAILABLE_PRICEPOINTS,
  PRICEPOINT_TYPE_PRICE,
  PRICEPOINT_TYPE_PACK,
  PRICEPOINT_PREMIUM,
  PRICEPOINT_REGULAR,
} from '../../../../constants/misc';

import { GET_LICENSES } from '../../../../graphql/queries/license';

import EmptyStateBuyer from './EmptyStateBuyer';
import Link from '../../../general/link';

const StyledSeparator = styled.div`
  margin-bottom: 22px;
`;

const StyledLicenseName = styled.div`
  max-width: 148px;
  display: flex;
`;

const StyledButtonWrapper = styled(Box)`
  text-align: center;
  width: 100%;
`;

type Props = {
  isEnterpriseCustomer: boolean,
};

const EnterpriseLicenseData = (props: Props) => {
  const { data = {} } = useQuery(GET_LICENSES, { fetchPolicy: 'network-only' });
  const { licenses }: { licenses?: LicensingOption[] } = data;
  if (!licenses) {
    return null;
  }
  const getStatsForLicense = (pricePoint: PricePoint) => {
    if (props.isEnterpriseCustomer) {
      return pricePoint.type === PRICEPOINT_TYPE_PRICE ||
        (pricePoint.type === PRICEPOINT_TYPE_PACK &&
          pricePoint.available === -1)
        ? t('navbar.profileMenu.unlimited')
        : `${pricePoint.remaining} / ${pricePoint.available}`;
    }

    return `${pricePoint.remaining} / ${pricePoint.available}`;
  };

  const hasLicensesForPricePoint = (pricePoint: string) => {
    if (props.isEnterpriseCustomer) {
      return find(licenses, (option) => option.pricePoints[pricePoint]);
    }
    return find(
      licenses,
      (option) => option?.pricePoints[pricePoint]?.type === PRICEPOINT_TYPE_PACK
    );
  };

  const renderLicenseInfo = (licensingOptions: LicensingOption[]) =>
    licensingOptions.length > 0 ? (
      <>
        {AVAILABLE_PRICEPOINTS.map((pricePoint) => (
          <div key={`pricepoint-${pricePoint}`}>
            {hasLicensesForPricePoint(pricePoint) && (
              <StyledSeparator>
                <Box mb={3} mt={4}>
                  <Text bold>
                    {t(`navbar.profileMenu.availableDownloads.${pricePoint}`)}
                  </Text>
                </Box>
                {licensingOptions.map((license: LicensingOption) =>
                  (props.isEnterpriseCustomer &&
                    license.pricePoints[pricePoint]) ||
                  (!props.isEnterpriseCustomer &&
                    license.pricePoints[pricePoint].type !==
                      PRICEPOINT_TYPE_PRICE) ? (
                    <Flex
                      flexDirection="column"
                      pb={3}
                      key={`${license.name}${shortid.generate()}`}>
                      <Flex justifyContent="space-between">
                        <Box>
                          <StyledLicenseName>
                            <Text>
                              {t(`licenses.types.${license.id}.title`)}
                            </Text>
                          </StyledLicenseName>
                          <Text color="grey50">
                            {`${t(
                              'navbar.profileMenu.downloadsLeft'
                            )}: ${getStatsForLicense(
                              license.pricePoints[pricePoint]
                            )}`}
                          </Text>
                        </Box>
                      </Flex>
                    </Flex>
                  ) : null
                )}
              </StyledSeparator>
            )}
          </div>
        ))}
      </>
    ) : undefined;

  return (
    <Box pt={2} px={4}>
      {!props.isEnterpriseCustomer &&
      !hasLicensesForPricePoint(PRICEPOINT_PREMIUM) &&
      !hasLicensesForPricePoint(PRICEPOINT_REGULAR) ? (
        <EmptyStateBuyer />
      ) : (
        renderLicenseInfo(licenses)
      )}
      {!props.isEnterpriseCustomer && (
        <StyledButtonWrapper py={3}>
          <Link href="/market/pricing">
            <Button fullWidth>
              {t('navbar.licensesMenu.emptyStateBuyer.button')}
            </Button>
          </Link>
        </StyledButtonWrapper>
      )}
    </Box>
  );
};

export default EnterpriseLicenseData;
