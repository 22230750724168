import styled from '@emotion/styled';
import { Flex } from '@eyeem-ui/atoms';

const navBarItemWrapper = styled(Flex)`
  border-radius: ${({ theme }) => `${theme.radii[2]}`};
  &:hover {
    background-color: ${({ theme }) => `${theme.colors.ghost60}`};
  }
`;

export default navBarItemWrapper;
