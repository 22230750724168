/* @flow */
import { connect } from 'react-redux';

import {
  getAuthUserNickname,
  getAuthUser,
  getIsBuyer,
  getIsEnterpriseCustomer,
  getIsSeller,
  getCheckoutCurrency,
} from '../../../reducers/';

import { getEnterpriseUserLicensingThunk } from '../../../helpers/actionCreators/redux';

import LicenceLink from './licenceLink.jsx';

const mapStateToProps = (state) => ({
  authUserNickname: getAuthUserNickname(state),
  authUser: getAuthUser(state),
  isBuyer: getIsBuyer(state),
  isEnterpriseCustomer: getIsEnterpriseCustomer(state),
  isSeller: getIsSeller(state),
  currency: getCheckoutCurrency(state),
});

const mapDispatchToProps = {
  getEnterpriseUserLicensing: getEnterpriseUserLicensingThunk,
};

export default connect(mapStateToProps, mapDispatchToProps)(LicenceLink);
