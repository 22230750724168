/* @flow */
/*
 * This component is a replacement for the usual smartbanner provided by
 * a mobile operating system
 */
import styled from '@emotion/styled';
import t from 'counterpart';
import { Star } from '@eyeem-ui/icons';
import { ThemeProvider } from '@eyeem-ui/theme';

import Photo from '../general/photo';

import { TEST_APP_BANNER } from '../../constants/pageObjectSelectors';

import {
  getStoreLink,
  handleAppOpenFailure,
  stripUrlParameters,
  track,
} from '../../helpers/tools';

const AppIcon = styled(Photo)`
  float: left;
  height: 36px;
  margin-right: 12px;
  margin-top: -2px;
  width: 36px;
`;

const Headline = styled.span`
  color: #333;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.125rem;
`;

const Subline = styled.span`
  color: #999;
  display: block;
  font-size: 0.75rem;
  line-height: 0.875rem;
`;

const RatingBar = styled.span`
  color: #ffaa33;
  font-size: 0.875rem;
  padding-left: 4px;
`;

const Container = styled.div`
  background: white;
  font-family: -apple-system, '.SFNSText-Regular', 'Helvetica Neue', 'Roboto',
    sans-serif;
  height: 56px;
  overflow: hidden;
  padding: 12px 16px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 11;
`;

type Props = {
  isIOS: boolean,
  isLoginViaParam: boolean,
  operatingSystem: string,
  path: string,
};

const AppBanner = ({
  isIOS,
  isLoginViaParam,
  operatingSystem,
  path,
}: Props) => {
  // if its ios we need to create the app deeplinks (doesn't make sense on android, as
  // they would've already intercepted the URL before getting to this point.)
  const currentPath = stripUrlParameters(path);

  const href = isIOS
    ? `eyeem://open/${currentPath}`
    : getStoreLink(operatingSystem);

  return (
    <Container data-test-id={TEST_APP_BANNER}>
      <a
        onClick={() => {
          track({
            eventType: 'click_outbound',
            eventName: `cta_appBanner_${operatingSystem}`,
            eventAction: 'click_button',
            eventLabel: t('appBanner.button'),
            eventPosition: 'appBanner_top',
          });
          if (isIOS && !isLoginViaParam) {
            handleAppOpenFailure.bind(null, operatingSystem);
          }
        }}
        className="g_btn g_btn-aqua g_btn-small float-right"
        href={href}>
        {t('appBanner.button')}
      </a>
      <AppIcon url="/node-static/img/appBanner/ios.png" retinafy />
      <Headline>{t('appBanner.headline')}</Headline>
      <RatingBar>
        <ThemeProvider>
          <Star
            color="#ffaa33"
            size="14"
            sx={{ fill: '#ffaa33' }}
            display="inline"
          />
          <Star
            color="#ffaa33"
            size="14"
            sx={{ fill: '#ffaa33' }}
            display="inline"
          />
          <Star
            color="#ffaa33"
            size="14"
            sx={{ fill: '#ffaa33' }}
            display="inline"
          />
          <Star
            color="#ffaa33"
            size="14"
            sx={{ fill: '#ffaa33' }}
            display="inline"
          />
          <Star
            color="#ffaa33"
            size="14"
            sx={{ fill: '#ffaa33' }}
            display="inline"
          />
        </ThemeProvider>
      </RatingBar>
      <Subline>{t(`appBanner.subline.${operatingSystem}`)}</Subline>
    </Container>
  );
};

export default AppBanner;
