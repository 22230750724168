// @flow
import { useState, useEffect, useRef } from 'react';
import { media, ThemeProvider } from '@eyeem-ui/theme';
import { Flex, Box, Text, Button, Buttonizer } from '@eyeem-ui/atoms';
import { Dropdown, Collapsible } from '@eyeem-ui/organisms';
import { Chevron, Hamburger, Close, UserCircle } from '@eyeem-ui/icons';

import styled from '@emotion/styled';
import t from 'counterpart';

import {
  Nav,
  StyledMobileNavList,
  StyledNavList,
  StyledNavText,
  StyledNavLink,
} from './styles';

import Cart from '../cart';
import Link from '../../general/link';
import Logo from '../logo';
import InfoLabel from '../../general/infoLabel';
import ContactSalesButtonNavbar from '../contactSalesButtonNavbar';

import { track } from '../../../helpers/tools';

import { NAVBAR_TRACKING } from './trackingStubs';
import { getPhotographersItems, loginItems, getMoreItems } from './items/items';

const BackToAppButton = styled(Button)`
  border-radius: 10px;
  ${({ isMobile }) => (isMobile ? 'width: 100%;' : '')}
`;

// link item in top nav
const StyledNavAnchorWrapper = styled(Box)`
  display: block;
  flex: auto;
  padding: 8px 12px;
  border-radius: 10px;
  &:hover {
    background-color: ${({ theme }) => `${theme.colors.ghost60}`};
    opacity: 1;
  }
  /* OVERWRITE WEBFLOW LINE HEIGHT */
  line-height: 1 !important;
  transition: background-color 0.2s ease-in-out;
`;

// Wrapper for our custom dropdown triggers
const StyledNavItemWrapper = styled(Flex)`
  font-weight: 400;
  padding: 8px 12px;
  border-radius: 10px;
  &:hover {
    background-color: ${({ theme }) => `${theme.colors.ghost60}`};
  }
  background-color: ${({ isActive, theme }) =>
    isActive && `${theme.colors.ghost60}`};
  transition: background-color 0.2s ease-in-out;
`;

// wrapper for icons in rightSideNav
const StyledNavIconWrapper = styled(Flex)`
  border-radius: 10px;
  &:hover {
    background-color: ${({ theme }) => `${theme.colors.ghost60}`};
    opacity: 1;
  }
  transition: background-color 0.2s ease-in-out;
`;

// New Label for Book Photoshoots
const StyledNewLabelWrapper = styled(Flex)`
  ${({ position }) =>
    position === 'after'
      ? `display: inline-block;
         line-height: 1;
         position: relative;
         top: -2px;
         `
      : `padding: 2px 4px;
         position: absolute;
         top: -12px;
         right: -18px;`}
`;

const RelativeBox = styled(Box)`
  position: relative;
`;

// top nav bar items (only shown on x-large viewports)
const StyledListItem = styled.li`
  display: none;

  @media ${media.xlargeUp} {
    display: block;
  }
`;

// toggle for the side nav (shown under x-large viewport)
const StyledSideBarToggleListItem = styled.li`
  display: block;

  @media ${media.xlargeUp} {
    display: none;
  }

  ${({ isTablet, isMobile }) => (isTablet || isMobile) && `display: block;`};
`;

// sideNav (display under x-large viewport)
const StyledMobileSidenavBox = styled(Box)`
  display: block;

  @media ${media.xlargeUp} {
    display: none;
  }

  ${({ isTablet, isMobile }) => (isTablet || isMobile) && `display: block;`};
`;

type Props = {
  className: string,
  currency: string,
  getEnterpriseUserLicensing: Function,
  isAuthenticated: boolean,
  isBuyer: boolean,
  isEnterprise: boolean,
  isMobile: boolean,
  isNavbarTransparent: boolean,
  isPhotographer: boolean,
  isTablet: boolean,
};

function NavbarInfo({
  className,
  currency,
  getEnterpriseUserLicensing,
  isAuthenticated,
  isBuyer,
  isEnterprise,
  isMobile,
  isNavbarTransparent,
  isPhotographer,
  isTablet,
}: Props) {
  const node = useRef();

  const moreItems = getMoreItems(isMobile, isTablet);
  const forPhotographersItems = getPhotographersItems(isMobile, isTablet);
  const [isMobileNavShown, setIsMobileNavShown] = useState(false);
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const [forPhotographersMenuOpen, setForPhotographersMenuOpen] =
    useState(false);

  const handleClickOutside = (event) => {
    if (node.current && node.current.contains(event.target)) {
      // clicking anywhere on the nav will close the top navbar dropdowns if open
      // do not do this if the sidenav is open
      !isMobileNavShown && isMoreMenuOpen && setIsMoreMenuOpen(false);
      !isMobileNavShown &&
        forPhotographersMenuOpen &&
        setForPhotographersMenuOpen(false);
      return;
    }
    // outside click
    setIsMobileNavShown(false);
    setIsMoreMenuOpen(false);
    setForPhotographersMenuOpen(false);
  };

  useEffect(() => {
    getEnterpriseUserLicensing(currency);
  }, []);

  useEffect(() => {
    if (isMobileNavShown || isMoreMenuOpen || forPhotographersMenuOpen) {
      window.document.addEventListener('mousedown', handleClickOutside);
    } else {
      window.document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      window.document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileNavShown, isMoreMenuOpen, forPhotographersMenuOpen]);

  useEffect(() => {
    if (isMobile && isMobileNavShown) {
      window.document.body.style.overflow = 'hidden';
    } else {
      window.document.body.style.overflow = 'auto';
    }
  }, [isMobile, isMobileNavShown]);

  const eventPosition = isAuthenticated
    ? 'navbar_info_loggedIn'
    : 'navbar_info_loggedOut';

  Object.keys(NAVBAR_TRACKING).forEach((key) => {
    return (NAVBAR_TRACKING[key].eventPosition = eventPosition);
  });

  NAVBAR_TRACKING.MORE_MENU_TRACKING.eventLabel = !isMoreMenuOpen
    ? 'expand'
    : 'collapse';
  NAVBAR_TRACKING.FOR_PHOTOGRAPHERS_MENU_TRACKING.eventLabel =
    !forPhotographersMenuOpen ? 'expand' : 'collapse';

  const TriggerMoreDropdown = ({ isActive }) => (
    <StyledNavItemWrapper
      isActive={isActive}
      alignItems="center"
      justifyContent="space-between"
      onClick={() => {
        setIsMoreMenuOpen(!isMoreMenuOpen);
        return track(NAVBAR_TRACKING.MORE_MENU_TRACKING);
      }}>
      <StyledNavText bold>{t('navbar.info.more.label')}</StyledNavText>
      <Box ml={1}>
        <Chevron rotate={isMoreMenuOpen ? 'up' : 'down'} />
      </Box>
    </StyledNavItemWrapper>
  );

  const TriggerForPhotographersDropdown = ({ isActive }) => (
    <StyledNavItemWrapper
      isActive={isActive}
      alignItems="center"
      justifyContent="space-between"
      onClick={() => {
        setForPhotographersMenuOpen(!forPhotographersMenuOpen);
        return track(NAVBAR_TRACKING.FOR_PHOTOGRAPHERS_MENU_TRACKING);
      }}>
      <StyledNavText bold>{t('navbar.info.photographers.label')}</StyledNavText>
      <Box ml={1}>
        <Chevron rotate={forPhotographersMenuOpen ? 'up' : 'down'} />
      </Box>
    </StyledNavItemWrapper>
  );

  const TriggerLoginDropdown = (
    <StyledNavIconWrapper p={2}>
      <UserCircle size="24" />
    </StyledNavIconWrapper>
  );

  return (
    <ThemeProvider>
      <Nav
        ref={node}
        className={className}
        isTransparent={isNavbarTransparent}
        isMobile={isMobile}
        isTablet={isTablet}
        isMobileNavShown={isMobileNavShown}>
        <Flex justifyContent="space-between" alignItems="center" width={1}>
          <StyledNavList>
            <li>
              <Box mr={[1, 1, 1, 5]}>
                <Logo eventPosition="navbar.eventPosition.info" />
              </Box>
            </li>
            {!isMobile && !isTablet && (
              <>
                {!isPhotographer && (
                  <StyledListItem>
                    <Box mr={2}>
                      <StyledNavLink
                        href="/"
                        onClick={() =>
                          track(NAVBAR_TRACKING.INFO_NAV_BUY_STOCK_PHOTOS)
                        }>
                        <StyledNavText bold>
                          {t('navbar.info.buyStockPhotos')}
                        </StyledNavText>
                      </StyledNavLink>
                    </Box>
                  </StyledListItem>
                )}
                <StyledListItem>
                  <Box mr={2}>
                    <StyledNavLink
                      href="/magazine"
                      onClick={() =>
                        track(NAVBAR_TRACKING.INFO_NAV_MAGAZINE_TRACKING)
                      }>
                      <RelativeBox>
                        <StyledNavText bold>
                          {t('navbar.info.more.magazine')}
                        </StyledNavText>
                      </RelativeBox>
                    </StyledNavLink>
                  </Box>
                </StyledListItem>
                {!isEnterprise && (
                  <StyledListItem>
                    <Box mr={2}>
                      <StyledNavLink
                        href="/market/pricing"
                        onClick={() => track(NAVBAR_TRACKING.INFO_NAV_PRICING)}>
                        <StyledNavText bold>
                          {t('navbar.info.pricing')}
                        </StyledNavText>
                      </StyledNavLink>
                    </Box>
                  </StyledListItem>
                )}
                <StyledListItem>
                  <Box>
                    <Dropdown
                      disableSelection
                      items={moreItems}
                      portal={false}
                      variant="transparent"
                      trigger={<TriggerMoreDropdown />}
                    />
                  </Box>
                </StyledListItem>
              </>
            )}
          </StyledNavList>

          <StyledNavList>
            {!isMobile && !isTablet && (
              <>
                <StyledListItem>
                  <Box mr={1}>
                    <Dropdown
                      disableSelection
                      initialTitle="Pick an option"
                      items={forPhotographersItems}
                      portal={false}
                      variant="transparent"
                      trigger={<TriggerForPhotographersDropdown />}
                    />
                  </Box>
                </StyledListItem>
                <StyledListItem>
                  <ContactSalesButtonNavbar />
                </StyledListItem>
              </>
            )}
            {/* ADD HERE */}
            {(!isAuthenticated || (isBuyer && !isEnterprise)) && (
              <li>
                <Box mr={[2, isAuthenticated ? 3 : 1, isAuthenticated ? 3 : 2]}>
                  <Cart />
                </Box>
              </li>
            )}
            {/* LoginDropdown */}
            {isAuthenticated === false && (
              <li>
                <Box mr={1}>
                  <Dropdown
                    trigger={TriggerLoginDropdown}
                    items={loginItems}
                    variant="transparent"
                  />
                </Box>
              </li>
            )}
            {isAuthenticated && !isMobile && (
              <Box mr={[0, 2, isTablet ? 2 : 0]}>
                <Link href="/">
                  <BackToAppButton
                    onClick={() => track(NAVBAR_TRACKING.INFO_NAV_BACK_TO_APP)}>
                    {t('navbar.backToApp')}
                  </BackToAppButton>
                </Link>
              </Box>
            )}
            {/* Mobile Sidenav trigger*/}
            <StyledSideBarToggleListItem
              isMobile={isMobile}
              isTablet={isTablet}>
              <Buttonizer
                onClick={() => {
                  setIsMobileNavShown(!isMobileNavShown);
                  // reset those so that on opening sideNav again both collapsibles are closed
                  setIsMoreMenuOpen(false);
                  setForPhotographersMenuOpen(false);
                }}>
                {isMobileNavShown ? (
                  <StyledNavIconWrapper justifyContent="center" p={2}>
                    <Close size="24" />
                  </StyledNavIconWrapper>
                ) : (
                  <StyledNavIconWrapper justifyContent="center" p={2}>
                    <Hamburger size="24" />
                  </StyledNavIconWrapper>
                )}
              </Buttonizer>
            </StyledSideBarToggleListItem>
          </StyledNavList>
        </Flex>

        {/* Mobile Sidenav */}
        {isMobileNavShown && (
          <StyledMobileSidenavBox
            py={3}
            isMobile={isMobile}
            isTablet={isTablet}>
            <StyledMobileNavList
              flex={1}
              isMobile={isMobile}
              isTablet={isTablet}
              isMobileNavShown={isMobileNavShown}>
              {isAuthenticated && isMobile && (
                <li>
                  <Box mt={3} px={2}>
                    <Link href="/">
                      <BackToAppButton
                        isMobile
                        onClick={() =>
                          track(NAVBAR_TRACKING.INFO_NAV_BACK_TO_APP)
                        }>
                        {t('navbar.backToApp')}
                      </BackToAppButton>
                    </Link>
                  </Box>
                </li>
              )}
              <li>
                <Box mt={3} px={2}>
                  <StyledNavLink
                    href="/"
                    onClick={() =>
                      track(NAVBAR_TRACKING.INFO_NAV_BUY_STOCK_PHOTOS)
                    }>
                    <StyledNavText bold>
                      {t('navbar.info.buyStockPhotos')}
                    </StyledNavText>
                  </StyledNavLink>
                </Box>
              </li>
              <li>
                <Flex alignItems="center" px={2} width={1}>
                  <StyledNavLink
                    href="/magazine"
                    onClick={() =>
                      track(NAVBAR_TRACKING.INFO_NAV_MAGAZINE_TRACKING)
                    }>
                    <StyledNavText bold>
                      {t('navbar.info.more.magazine')}
                    </StyledNavText>
                  </StyledNavLink>
                </Flex>
              </li>
              <li>
                <Box px={2}>
                  <StyledNavLink
                    href="/market/pricing"
                    onClick={() => track(NAVBAR_TRACKING.INFO_NAV_PRICING)}>
                    <StyledNavText bold>
                      {t('navbar.info.pricing')}
                    </StyledNavText>
                  </StyledNavLink>
                </Box>
              </li>
              <li>
                <Box px={2}>
                  <ContactSalesButtonNavbar />
                </Box>
              </li>
              {/* more collapsible */}
              <li>
                <Box px={2}>
                  <Collapsible
                    trigger={<TriggerMoreDropdown isActive={isMoreMenuOpen} />}
                    name="moreCollapsible"
                    isOpen={isMoreMenuOpen}
                    onToggle={null}>
                    <Box mb={2}>
                      {moreItems.map((item) => (
                        <>
                          {item.component ? (
                            <StyledNavAnchorWrapper>
                              {item.component}
                            </StyledNavAnchorWrapper>
                          ) : (
                            <StyledNavLink
                              href={item.href}
                              onClick={item.onSelect}
                              key={item.href}>
                              <Flex alignItems="center">
                                <item.icon size="24" />
                                <Box pl={2}>
                                  <Text bold>{item.text}</Text>
                                </Box>
                              </Flex>
                            </StyledNavLink>
                          )}
                        </>
                      ))}
                    </Box>
                  </Collapsible>
                </Box>
              </li>
              {/* For Photographers collapsible */}
              <li>
                <Box px={2}>
                  <Collapsible
                    trigger={
                      <TriggerForPhotographersDropdown
                        isActive={forPhotographersMenuOpen}
                      />
                    }
                    name="forPhotographersCollapsible"
                    isOpen={forPhotographersMenuOpen}>
                    <Box mb={3}>
                      {forPhotographersItems.map((item) => (
                        <>
                          {item.component ? (
                            <StyledNavAnchorWrapper>
                              {item.component}
                            </StyledNavAnchorWrapper>
                          ) : (
                            <StyledNavLink
                              href={item.href}
                              onClick={item.onSelect}
                              key={item.href}>
                              <Flex alignItems="center">
                                <item.icon size="24" />
                                <Box pl={2}>
                                  <Text bold>{item.text}</Text>
                                </Box>
                              </Flex>
                            </StyledNavLink>
                          )}
                        </>
                      ))}
                    </Box>
                  </Collapsible>
                </Box>
              </li>
            </StyledMobileNavList>
          </StyledMobileSidenavBox>
        )}
      </Nav>
    </ThemeProvider>
  );
}

export default NavbarInfo;
