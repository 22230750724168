// @flow
import {
  Awards,
  Camera,
  Earnings,
  Email,
  EyeEm,
  Heart,
  Indoor,
  Lock,
  Magazine,
  Missions,
  Support,
  Team,
  UserAdd,
} from '@eyeem-ui/icons';

import t from 'counterpart';

import { track } from '../../../../helpers/tools';
import { NAVBAR_TRACKING } from '../trackingStubs';

export const getPhotographersItems = () => [
  {
    text: t('navbar.info.photographers.sell'),
    icon: Earnings,
    href: '/sell-your-work',
    onSelect: () => track(NAVBAR_TRACKING.SUB_MENU_CREATOR_SELL_TRACKING),
  },
  {
    text: t('navbar.info.photographers.join'),
    icon: Team,
    href: '/creators',
    onSelect: () => track(NAVBAR_TRACKING.SUB_MENU_CREATOR_JOIN_TRACKING),
  },
  {
    text: t('navbar.info.photographers.awards'),
    icon: Awards,
    href: '/awards',
    onSelect: () => track(NAVBAR_TRACKING.SUB_MENU_CREATOR_AWARDS_TRACKING),
  },
  {
    text: t('navbar.info.photographers.missions'),
    icon: Missions,
    href: '/missions/photographers',
    onSelect: () => track(NAVBAR_TRACKING.SUB_MENU_CREATOR_MISSIONS_TRACKING),
  },
];

export const loginItems = [
  {
    text: t('navbar.info.buttons.login'),
    icon: Indoor,
    href: '/login',
    onSelect: () => track(NAVBAR_TRACKING.LOGIN_TRACKING),
  },
  {
    text: t('navbar.info.buttons.signup'),
    icon: UserAdd,
    href: '/signup',
    onSelect: () => track(NAVBAR_TRACKING.SIGNUP_TRACKING),
  },
];

export const getMoreItems = () => {
  return [
    {
      text: t('navbar.info.more.story'),
      icon: EyeEm,
      href: '/story',
      onSelect: () => track(NAVBAR_TRACKING.SUB_MENU_STORY_TRACKING),
    },
    {
      text: t('navbar.info.more.terms'),
      icon: Lock,
      href: '/tos',
      onSelect: () => track(NAVBAR_TRACKING.SUB_MENU_TOS_TRACKING),
    },
    {
      text: t('navbar.info.more.faq'),
      icon: Support,
      href: 'https://eyeem.zendesk.com/hc/en-us',
      onSelect: () => track(NAVBAR_TRACKING.SUB_MENU_FAQ_TRACKING),
    },
    {
      text: t('navbar.info.more.jobs'),
      icon: Heart,
      href: '/jobs',
      onSelect: () => track(NAVBAR_TRACKING.SUB_MENU_JOBS_TRACKING),
    },
    {
      text: t('navbar.info.more.contact'),
      icon: Email,
      href: '/contact',
      onSelect: () => track(NAVBAR_TRACKING.SUB_MENU_CONTACT_TRACKING),
    },
  ];
};
