import NavbarInfo from './navbarInfo';

import { connect } from 'react-redux';

import {
  getCheckoutCurrency,
  getIsMobile,
  getIsTablet,
  getIsBuyer,
  getIsEnterpriseCustomer,
  getIsPhotographer,
} from '../../../reducers/';
import { getEnterpriseUserLicensingThunk } from '../../../helpers/actionCreators/redux';

const mapStateToProps = (state) => ({
  currency: getCheckoutCurrency(state),
  isMobile: getIsMobile(state),
  isTablet: getIsTablet(state),
  isEnterprise: getIsEnterpriseCustomer(state),
  isBuyer: getIsBuyer(state),
  isPhotographer: getIsPhotographer(state),
});

export default connect(mapStateToProps, {
  getEnterpriseUserLicensing: getEnterpriseUserLicensingThunk,
})(NavbarInfo);
