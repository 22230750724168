import { connect } from 'react-redux';
import { compose } from 'redux';

import Lightbox from './lightbox.jsx';

import {
  getIsLightboxStripOpen,
  getFlyout,
  getSelectedLightboxId,
  getIsTablet,
  getIsEnterpriseCustomer,
} from '../../../reducers/';

import {
  clearFlyout,
  toggleLightboxStripThunk,
} from '../../../helpers/actionCreators/redux';

const mapStateToProps = (state) => ({
  photoId: getFlyout(state).photoId,
  flyout: getFlyout(state),
  flyoutAsset: getFlyout(state).asset,
  isLightboxStripOpen: getIsLightboxStripOpen(state),
  contextLightboxId: getSelectedLightboxId(state),
  isTablet: getIsTablet(state),
  isEnterpriseCustomer: getIsEnterpriseCustomer(state),
});

export default compose(
  connect(mapStateToProps, {
    clearFlyout,
    toggleLightboxStrip: toggleLightboxStripThunk,
  })
)(Lightbox);
