// @flow
import { MobileSidenavToggle } from '@eyeem-ui/organisms';
import { TOGGLE_SIDENAV_PUBSUB_EVENT } from '../../constants/misc';

function SideNavToggle(props: { isOpen: boolean }) {
  return (
    <li>
      <MobileSidenavToggle
        handleClick={() => {
          window.mitt.emit(TOGGLE_SIDENAV_PUBSUB_EVENT, {
            isOpen: props.isOpen, // redux thunk transforms this later
          });
        }}
        isOpen={props.isOpen}
      />
    </li>
  );
}

export default SideNavToggle;
