import { connect } from 'react-redux';
import { compose } from 'redux';
import { gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';

import { clearFlyout } from '../../../helpers/actionCreators/redux';
import Cart from './cart';

import {
  getFlyout,
  getCartPhotos,
  getCartDeals,
  getIsTablet,
  getCheckoutCurrency,
  getUserLicensing,
  getIsAuthenticated,
} from '../../../reducers/';

import { SimplePhotoFragment } from '../../../graphql/fragments/photo';
import { SimpleUserFragment } from '../../../graphql/fragments/user';

const PhotoQuery = gql`
  query navCartPhoto($photoId: ID!, $isPhotographer: Boolean!) {
    photo(photoId: $photoId, isPhotographer: $isPhotographer) {
      ...SimplePhoto
      user {
        ...SimpleUser
      }
    }
  }

  ${SimplePhotoFragment}
  ${SimpleUserFragment}
`;

const mapStateToProps = (state) => {
  const flyout = getFlyout(state);
  const cartPhotos = getCartPhotos(state);
  const photoId = flyout?.item?.id;
  return {
    flyout,
    photoId,
    cartPhotos,
    cartDeals: getCartDeals(state),
    isTablet: getIsTablet(state),
    currency: getCheckoutCurrency(state),
    userLicences: getUserLicensing(state),
    isAuthenticated: getIsAuthenticated(state),
  };
};

export default compose(
  connect(mapStateToProps, {
    clearFlyout,
  }),
  graphql(PhotoQuery, {
    props: ({ data: { photo }, ownProps }) => ({ photo, ...ownProps }),
    options: (ownProps) => ({
      variables: {
        photoId: ownProps.photoId,
        isPhotographer: false,
      },
    }),
    skip: (ownProps) => !ownProps.photoId,
  })
)(Cart);
