import styled from '@emotion/styled';
import { media } from '@eyeem-ui/theme';
import { Text } from '@eyeem-ui/atoms';

import { NAVBAR_HEIGHT } from '../../../constants/misc';
import baseTheme from '../../../helpers/theme';
import Link from '../../general/link';

// comes from the designs.. needed to get 40px height
export const StyledNavText = styled(Text)`
  line-height: 24px;
  white-space: nowrap;
`;

// link item in top nav
export const StyledNavLink = styled(Link)`
  display: block;
  flex: auto;
  padding: 8px 12px;
  border-radius: 10px;
  &:hover {
    background-color: ${({ theme }) => `${theme.colors.ghost60}`};
    opacity: 1;
  }
  /* OVERWRITE WEBFLOW LINE HEIGHT */
  line-height: 1 !important;
  transition: background-color 0.2s ease-in-out;
`;

export const Nav = styled('nav')`
  /* 1. Override webflow pages */
  background-color: ${({ isTransparent, theme, isMobileNavShown }) =>
    isTransparent && !isMobileNavShown
      ? 'transparent'
      : theme.colors.grey20} !important;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;

  &:after {
    position: absolute;
    content: '';
    height: 1px;
    width: 100%;
    transition: background-color 0.2s ease;
    background-color: ${({ isTransparent }) =>
      isTransparent ? 'transparent' : 'black'};
    bottom: 0;
    left: 0;
  }

  @media ${media.mediumUp} {
    padding-left: 32px;
    padding-right: 32px;
  }
  @media ${media.xlargeUp} {
    padding-left: 40px;
    padding-right: 40px;
  }

  ${({ isMobile }) =>
    isMobile &&
    `padding-left: 20px;
      padding-right: 20px;`};

  ${({ isTablet }) =>
    isTablet &&
    `padding-left: 32px;
      padding-right: 32px;`};
`;

export const StyledMobileNavList = styled('ul')`
  background-color: ${({ theme }) => theme.colors.grey20};
  transition: ${({ isMobileNavShown }) => {
    const heightDelay = isMobileNavShown ? '0s' : '0.2s';
    return `transform 0.2s ease, background-color 0.2s ease, height 0s ease ${heightDelay}`;
  }};
  position: absolute;

  right: ${({ isMobileNavShown, isMobile }) =>
    isMobileNavShown && isMobile && 0};
  top: 0;
  overflow: scroll;
  width: ${({ isMobile }) => (isMobile ? '100vw' : '300px')};
  height: ${({ isMobileNavShown }) => {
    if (!isMobileNavShown) return 0;
    return `calc(100vh - ${NAVBAR_HEIGHT}px)`;
  }};
  z-index: ${baseTheme.zIndices.navBar};

  transform: ${({ isMobileNavShown, isMobile }) => {
    if (isMobileNavShown) {
      return isMobile ? 'translateX(0)' : 'translateX(-268px)'; //isTablet: 300px nav width - 32px padding right
    }

    return 'translateX(+150%)';
  }};
  margin-top: ${`${NAVBAR_HEIGHT}px`};
`;

export const StyledNavList = styled.ul`
  display: flex;
  align-items: center;
`;
