/* @flow */
import { connect } from 'react-redux';
import PreviousDownloadsButton from './previousDownloadsButton.jsx';

import { getIsBCG, getIsEnterpriseCustomer } from '../../../reducers/';

const mapStateToProps = (state) => ({
  isBCG: getIsBCG(state),
  isEnterpriseCustomer: getIsEnterpriseCustomer(state),
});

export default connect(mapStateToProps)(PreviousDownloadsButton);
