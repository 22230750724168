import t from 'counterpart';
import { ThemeProvider } from '@eyeem-ui/theme';
import { Box } from '@eyeem-ui/atoms';

import { StyledNavLink, StyledNavText } from './navbarInfo/styles';

const ContactSalesButtonNavbar = () => (
  <ThemeProvider>
    <Box mr={1}>
      <StyledNavLink href="/market/pricing#form">
        <StyledNavText bold>{t('navbar.info.contactSales')}</StyledNavText>
      </StyledNavLink>
    </Box>
  </ThemeProvider>
);

export default ContactSalesButtonNavbar;
