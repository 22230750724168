/* @flow */

import { Flex, Text } from '@eyeem-ui/atoms';
import styled from '@emotion/styled';

const StyledInfoLabel = styled(Flex)`
  margin-bottom: 1px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.red50};
  padding: 1px 4px;
`;

const StyledInfoText = styled(Text)`
  font-size: 8px !important;
`;

type Props = {
  text: string,
};

function InfoLabel({ text }: Props) {
  return (
    <StyledInfoLabel>
      <StyledInfoText bold>{text}</StyledInfoText>
    </StyledInfoLabel>
  );
}

export default InfoLabel;
