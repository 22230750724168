/* @flow */
/* eslint-disable react/state-in-constructor */
import { Component } from 'react';
import t from 'counterpart';
import { Flex } from '@eyeem-ui/atoms';
import { Downloads } from '@eyeem-ui/icons';
import { ThemeProvider } from '@eyeem-ui/theme';

import EnterpriseLicenseData from './EnterpriseLicenseData';

import Dropdown from '../../general/dropdown/';
import Photo from '../../general/photo/';

import withRouter from '../../../helpers/hoc/withRouter.jsx';

import { TEST_TOPNAVBAR_PROFILE_ICON } from '../../../constants/pageObjectSelectors';

import { StyledNavItemUserImage } from '../styles';
import StyledNavIconWrapper from '../navBarItemWrapper';

type Props = {
  authUserNickname: string,
  isBuyer: boolean,
  authUser: AuthUser,
  eventPosition: string,
  currency: String,
  getEnterpriseUserLicensing: Function,
};

type State = {
  isDropdownOpen: boolean,
  canFetchUserData: boolean,
};

class NavbarLicenceLink extends Component<Props, State> {
  state = {
    isDropdownOpen: false,
    canFetchUserData: true,
  };

  getUserLink = () =>
    `/u/${
      this.props.authUserNickname && this.props.authUserNickname.toLowerCase()
    }`;

  toggle = (isDropdownOpen) => {
    this.setState({ isDropdownOpen });
  };

  getTrigger = () => {
    if (this.props.isBuyer) {
      return (
        <ThemeProvider>
          <StyledNavIconWrapper
            onMouseEnter={this.props.isBuyer ? this.fetchUserData : undefined}
            p={2}
            justifyContent="center"
            title={t('navbar.licensesMenu.label')}>
            <Downloads size="24" />
          </StyledNavIconWrapper>
        </ThemeProvider>
      );
    }
    return (
      <StyledNavItemUserImage>
        <Photo
          url={this.props.authUser && this.props.authUser.thumbUrl}
          size1="sq"
          size2="30"
        />
      </StyledNavItemUserImage>
    );
  };

  fetchUserData = () => {
    if (this.state.canFetchUserData) {
      this.props.getEnterpriseUserLicensing(this.props.currency);
      this.setState({ canFetchUserData: false });
      setTimeout(() => this.setState({ canFetchUserData: true }), 10000);
    }
  };

  render() {
    return (
      <ThemeProvider>
        <Flex
          title={t('navbar.licensesMenu.label')}
          alignItems="center"
          data-test-id={TEST_TOPNAVBAR_PROFILE_ICON}>
          <Dropdown
            isEnterpriseUserDropdown
            dropdownThemeDark
            disableSEOChildren
            trigger={this.getTrigger()}
            eventType={t('tracking.eventType.inbound')}
            eventAction={t('tracking.eventAction.icon')}
            eventName={t('navbar.licensesMenu.eventName')}
            eventLabel={t('navbar.licensesMenu.eventLabel')}
            eventPosition={this.props.eventPosition}
            toggleCallback={this.toggle}>
            {this.props.isBuyer && <EnterpriseLicenseData />}
          </Dropdown>
        </Flex>
      </ThemeProvider>
    );
  }
}

export default withRouter(NavbarLicenceLink);
